import React, { useLayoutEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import {
  Loading, useDataProvider, useNotify, useTranslate,
} from 'react-admin';
import { useLoading } from 'ra-core';
import AddCard from './AddCard';
import ShowCard from './ShowCard';
import HistoricSearchUi from '../../../lib/components/HistoricSearchUi';


const UserCards = ({ record }) => {
  const initialPage = 1;
  const perPage = 10;
  const [data, setData] = useState(undefined);
  const [page, setPage] = useState(undefined);
  const [total, setTotal] = useState(undefined);
  const [canPrev, setCanPrev] = useState(false);
  const [canNext, setCanNext] = useState(false);
  const [card, setCard] = useState(null);
  const [showCard, setShowCard] = useState(null);
  const dataProvider = useDataProvider();
  const loading = useLoading();
  const notify = useNotify();
  const { translate } = useTranslate;
  const zero = 0;


  useLayoutEffect(() => {
    if (record) {
      dataProvider.getList('cards', {
        pagination: {
          page: 1,
          limit: 14,
        },
        filter: {
          userId: record?.id,
        },
      }).then((res) => {
        const { total: totalResult, data: dataResult } = res;
        if (totalResult > zero) {
          return setCard(dataResult[zero]);
        }
        return setCard(null);
      }).catch((error) => {
        notify(translate('user.cards.errorQuery'), 'error');
        notify(error, 'error');
      });

      setPage(initialPage);
    }
  }, [record, dataProvider]);
  useLayoutEffect(() => {
    const isCardAvailable = card !== null;
    setShowCard(isCardAvailable);
  }, [card]);

  useLayoutEffect(() => {
    if (page && record?.id) {
      const variables = {
        pagination: {
          perPage,
          page,
        },
        filter: {
          userIds: [record.id],
        },
      };

      dataProvider
        .getList('cardsHistory', { ...variables })
        .then((res) => {
          const { data: queryData, pagination, total: totalCount } = res;
          setData(queryData);
          setTotal(totalCount);
          setCanPrev(pagination?.prev !== null);
          setCanNext(pagination?.next !== null);
        })
        .catch((err) => {
          notify(err?.message, 'error');
        });
    }
  }, [page, record, showCard]);

  if (!record || loading) {
    return <Loading />;
  }

  return (
    <Box sx={{ margin: '20px', boxSizing: 'border-box' }}>
      {!showCard && <AddCard record={record} setCard={setCard} />}
      {showCard && card && <ShowCard card={card} setCard={setCard} />}
      <HistoricSearchUi
        data={data}
        isDetail="users"
        loading={loading}
        total={total}
        canPrev={canPrev}
        canNext={canNext}
        page={page}
        setPage={setPage}
      />
    </Box>
  );
};

export default UserCards;
