import React from 'react';
import { Link } from 'react-router-dom';
import { linkToRecord } from 'ra-core';
import {
  TextField, useRecordContext,
} from 'react-admin';
import { makeStyles } from '@material-ui/core';
import get from 'lodash.get';

// eslint-disable-next-line
const iconString = `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 120 120'%3e%3cpath fill-opacity='0' stroke='%230062aa' stroke-width='10' stroke-linejoin='round' d='m53 45H15v60h60V67M55 15v10l10 10-30 30 20 20 30-30 10 10h10V15z'/%3e%3c/svg%3e")`;

const useStyles = makeStyles(theme => ({
  link: {
    textDecoration: 'none',
    color: 'inherit',
    width: '100%',
    display: 'inline-block',
  },
  linkBlue: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
    width: '100%',
    display: 'inline-block',

    '&:after': {
      content: iconString,
      display: 'inline-block',
      paddingLeft: '3px',
      width: '12px',
      height: '12px',
    },
  },
}));

const CardLinkTextField = (props) => {
  const {
    basePath, record = {}, source, permissions, path, defaultKey, isColorPrimary, ...rest
  } = props;
  const recordContext = useRecordContext(props);
  const classes = useStyles();
  const defaultKeyTo = defaultKey || 'id';
  let to;

  if (path && defaultKey) {
    to = `${path}/${get(record, defaultKeyTo)}`;
  } else {
    to = (path) || `${linkToRecord(basePath, record && record[defaultKeyTo])}`;
  }

  if (!recordContext.user) {
    return null;
  }

  return (
    <TextField
      className={isColorPrimary ? classes.linkBlue : classes.link}
      source={source}
      record={record}
      component={Link}
      to={to}
      {...rest}
    />
  );
};
export default CardLinkTextField;
