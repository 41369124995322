import React from 'react';
import {
  Datagrid,
  useTranslate,
  List,
  TextField,
} from 'react-admin';
import StatusField from './StatusField';

const PickupPointList = ({ permissions, ...props }) => {
  const t = useTranslate();

  return (
    <List
      {...props}
      bulkActionButtons={false}
      exporter={false}
    >
      <Datagrid rowClick="edit">
        <TextField source="name" label={t('pickupPoint.list.fields.name')} sortable={false} />
        <StatusField source="active" label={t('pickupPoint.list.fields.status')} sortable={false} />
      </Datagrid>
    </List>
  );
};

export default PickupPointList;
