import React from 'react';
import {
  Datagrid, List, TextField, DateField, Filter, TextInput, useTranslate, ListButton, useRedirect,
} from 'react-admin';
import PageviewIcon from '@material-ui/icons/Pageview';

import FullNameText from '../../lib/components/FullNameText';
import {
  CRF_CARD, CRF_PREFIX, PASS_CARD,
} from '../../lib/utils/cards';
import CardLinkTextField from '../../lib/components/CardsLinkField';

const CardFilter = (props) => {
  const translate = useTranslate();
  const error = translate('user.cards.luhn');
  const validateLuhn = (value) => {
    if (!value) {
      return undefined;
    }
    if (value.length === CRF_CARD && !value.startsWith(CRF_PREFIX)) {
      return error;
    }

    if (value.length !== CRF_CARD && value.length !== PASS_CARD) {
      return error;
    }

    return undefined;
  };
  return (
    <Filter {...props} data-testid="card-search">
      <TextInput
        source="code"
        label="Card Number"
        validate={validateLuhn}
        alwaysOn
        resettable
      />
      <ListButton
        style={{ marginRight: '5px' }}
        basePath="/cards/historic/search"
        label={translate('cards.history.search')}
        icon={<PageviewIcon />}
        alwaysOn
      />
    </Filter>
  );
};

const CardList = (props) => {
  const redirect = useRedirect();

  return (
    <List
      {...props}
      bulkActionButtons={false}
      exporter={false}
      filters={<CardFilter {...props} />}
      syncWithLocation={false}
    >
      <Datagrid rowClick={(id) => { redirect(`/cards/${id}`); }}>
        <TextField source="code" label="Card Number" />
        <TextField source="type" />
        <FullNameText label="Fullname" />
        <CardLinkTextField path="/users" defaultKey="user.id" source="user.username" label="Username" isColorPrimary />
        <DateField source="createdAt" />
      </Datagrid>
    </List>
  );
};

export default CardList;
