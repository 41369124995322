import * as React from 'react';
import { useRecordContext } from 'react-admin';

const FullNameText = (props) => {
  const record = useRecordContext(props);
  const output = (record?.user?.name && record?.user?.lastName) ? `${record.user.name} ${record.user.lastName}` : 'User deleted';

  return output === 'User deleted' ? <span style={{ fontStyle: 'italic' }}>{output}</span> : <span>{output}</span>;
};

export default FullNameText;
