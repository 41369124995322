import React, { useState } from 'react';
import {
  Button, Confirm, DateInput, FormDataConsumer,
  SelectInput, SimpleForm, TextInput, Toolbar, useDataProvider, useNotify, useTranslate,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import IconSave from '@material-ui/icons/Delete';
import { MALRG_BO_URL } from '../../../lib/utils';

const useStyles = makeStyles({
  inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
});


const ShowCard = ({ card, setCard }) => {
  const { id } = card;
  const notify = useNotify();
  const classes = useStyles();
  const translate = useTranslate();
  const dataProvider = useDataProvider();
  const [open, setOpen] = useState(false);

  const typeOptions = [
    { id: 'CRF', name: 'CRF' },
    { id: 'PASS', name: 'PASS' },
  ];
  const handleLink = (encryptedCard) => {
    const url = `${MALRG_BO_URL()}index.php?pshop_mode=admin&page=redenciones.abusos&option=com_virtuemart&filtro_abusos%5Bencrypted_card%5D=${encryptedCard}`;

    return window.open(url, '_blank');
  };
  const save = async () => {
    try {
      const response = await dataProvider.delete('cards', { id });
      if (response) {
        notify(`${translate('user.cards.success.delete')}${id}`, { type: 'success' });
        setCard(null);
      }
    } catch (error) {
      if (error && error.message) {
        notify(`Error: ${error.message}`, { type: 'error' });
      } else {
        notify(`${translate('user.cards.error')}`, { type: 'error' });
      }
    }
  };

  const CustomToolbar = props => (
    <Toolbar {...props}>
      <Button
        variant="contained"
        color="red"
        label="Delete Card"
        style={{ maxWidth: '140px', backgroundColor: 'red' }}
        onClick={() => { setOpen(true); }}
        startIcon={<IconSave />}
      />
    </Toolbar>
  );

  return (
    <>
      <Confirm
        isOpen={open}
        title=""
        content={translate('user.cards.confirm')}
        onConfirm={save}
        onClose={() => setOpen(false)}
      />
      <SimpleForm record={card} save={save} toolbar={<CustomToolbar />}>
        <Typography variant="h2" component="h2">
          {translate('user.cards.deleteCardTitle')}
        </Typography>
        <SelectInput
          options={{ disabled: true }}
          source="type"
          label={translate('user.cards.type')}
          formClassName={classes.inlineBlock}
          choices={typeOptions}
        />
        <TextInput
          options={{ disabled: true }}
          source="code"
          label={translate('user.cards.code')}
          formClassName={classes.inlineBlock}
        />
        <DateInput
          formClassName={classes.inlineBlock}
          source="createdAt"
          options={{ disabled: true }}
          label={translate('user.edit.createdAt')}
        />
        <FormDataConsumer>
          {({ formData }) => (
            <Button variant="outlined" className={classes.button} onClick={() => handleLink(formData.encryptedCode)} label="user.form.link_2" />
          )}
        </FormDataConsumer>
      </SimpleForm>
    </>
  );
};

export default ShowCard;
