import React from 'react';
import {
  FormDataConsumer,
  SaveButton, SelectInput, SimpleForm, TextInput, Toolbar, useDataProvider, useNotify, useTranslate,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import {
  CRF_CARD, CRF_PREFIX, PASS_CARD,
} from '../../../lib/utils/cards';

const useStyles = makeStyles({
  inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
});

const CustomToolbar = props => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);
const validateCardCreation = (values) => {
  const errors = {};
  if (!values.code) {
    errors.code = 'ra.validation.required';
  }
  if (!values.type) {
    errors.type = 'ra.validation.required';
  }
  return errors;
};
const AddCard = ({ record, setCard }) => {
  const notify = useNotify();
  const classes = useStyles();
  const translate = useTranslate();
  const dataProvider = useDataProvider();
  const CRF_PASS = CRF_PREFIX.toString();
  const { id } = record;
  const typeOptions = [
    { id: 'CRF', name: 'CRF' },
    { id: 'PASS', name: 'PASS' },
  ];
  const errorTxt = translate('user.cards.luhn');
  const validateLuhnPass = (value) => {
    if (!value) {
      return undefined;
    }
    if (value.length !== PASS_CARD) {
      return errorTxt;
    }

    return undefined;
  };
  const validateLuhnCRF = (value) => {
    if (!value) {
      return undefined;
    }
    const fullValue = CRF_PREFIX + value;
    if (fullValue.length !== CRF_CARD) {
      return errorTxt;
    }

    return undefined;
  };
  const validateLoyaltyCardExistsMessage = (msg) => {
    const regex = /^Loyalty card code `.+` exists\.$/;
    return regex.test(msg);
  };
  const save = async (values) => {
    const { type, code } = values;
    const fullCode = (type === 'CRF') ? CRF_PREFIX + code : code;
    const input = { code: fullCode, type, userId: id };
    try {
      const response = await dataProvider.create('cards', { data: input });
      if (response) {
        const { data } = response;
        notify(`${translate('user.cards.success.add')}${id}`, { type: 'success' });
        setCard(data);
      }
    } catch (error) {
      if (error && error.message) {
        const msg = validateLoyaltyCardExistsMessage(error.message) ? translate('user.cards.errorUser') : error.message;
        notify(`Error: ${msg}`, { type: 'error' });
      } else {
        notify(`${translate('user.cards.error')}`, { type: 'error' });
      }
    }
  };

  return (
    <SimpleForm save={save} toolbar={<CustomToolbar />} validate={validateCardCreation}>
      <Typography variant="h2" component="h2">
        {translate('user.cards.addCardTitle')}
      </Typography>
      <SelectInput source="type" label={translate('user.cards.type')} formClassName={classes.inlineBlock} choices={typeOptions} />
      <FormDataConsumer>
        {({ formData }) => {
          if (!formData.type) return null;
          if (formData.type === 'CRF') {
            return (
              <TextInput
                fullWidth
                source="code"
                formClassName={classes.inlineBlock}
                label={translate('user.cards.code')}
                validate={validateLuhnCRF}
                InputProps={{
                  className: classes.inlineBlock,
                  startAdornment: <InputAdornment position="start">{CRF_PASS}</InputAdornment>,
                }}
              />
            );
          }
          return (<TextInput validate={validateLuhnPass} fullWidth source="code" label={translate('user.cards.code')} formClassName={classes.inlineBlock} />);
        }
      }

      </FormDataConsumer>
    </SimpleForm>
  );
};

export default AddCard;
