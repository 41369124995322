import React from 'react';
import {
  Datagrid,
  useTranslate,
  useRecordContext,
  List,
  TextField,
} from 'react-admin';

const StatusField = ({ permissions, ...props }) => {
  const t = useTranslate();
  const record = useRecordContext();

  const renderRecord = () => ({
    status: record[props.source] ? t('pickupPoint.list.status.active') : t('pickupPoint.list.status.inactive'),
  });

  return (
    <TextField {...props} source="status" record={renderRecord()} />
  );
};

export default StatusField;
