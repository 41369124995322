import englishMessages from 'ra-language-english';

export const messages = {
  ...englishMessages,
  app: {
    hello: 'Hi, %{name}!',
    selectLanguage: 'Select language',
    required: 'This field is mandatory',
    recoverPassword: 'Forgot password?',
    back: 'Back to login',
    forgot: {
      submit: 'Send email',
      field: 'Email address',
      claim: 'Please enter your email address to reset your password',
      success: 'We have sent you an email to reset your password. Please check your inbox.',
      error: 'Please try again, technical error.',
    },
    check: {
      submit: 'Reset password',
      field: 'Password',
      field2: 'Confirm your password',
      errorFormat: 'Your password must contain : More than 8 characters, at least one upper and one lower case letter',
      errorSame: 'Your password must be the same',
      claim: 'Enter a new password',
      success: 'We\'ve reset your password, now you can log in to your account.',
      error: 'Please try again, technical error.',
    },
  },
  resources: {
    promotions: {
      name: 'Promotion |||| Promotions',
      fields: {
        status: 'Status',
      },
    },
    users: {
      name: 'User |||| Users',
      fields: {
        name: 'Name',
        role: 'Role',
      },
    },
    comments: {
      name: 'Comment |||| Comments',
      list: {
        addEan: 'Search by ean',
      },
      status: {
        success: 'Element updated',
      },
    },
    products: {
      fields: {
        accolade: {
          id: 'Accolade',
        },
      },
      seasons: {
        name: 'Season |||| seasons',
      },
    },

  },
  image: {
    success: 'Image uploaded successfully',
    tryagin: 'Some error in upload, please try again',
    error: 'upload image error',
    butonText: 'Add Image',
    promotionImage: 'Promotion Image',
    ImageDropLabel: 'Drop your image here',
    deleteWarning: ' Do you really want to remove the image?',
    resolutionError: 'unsupported resolution',
    delete: {
      success: 'Image removed successfully',
      tryAgain: 'Some error in removing, please try again',
      error: 'remove image error',
    },
    upload: {
      format: 'Format',
      formatValue: 'JPG / PNG',
      weight: 'Maximum weight',
      weightValue: '5 MB',
      size: 'Maximum size',
      sizeValue: '400 x 400 px',
      sizeValueRetailer: '150 x 150 px',
    },
  },
  promotion: {
    list: {
      search: 'Search',
      id: 'Key',
      ean: 'EAN',
      name: 'Name',
      startDate: 'Start date',
      state: 'Status',
      promotionName: 'Promotion Name',
      promotionKey: 'Promotion Key',
    },
    form: {
      name: 'Name',
    },
    edit: {
      title: 'Promotion "%{title}"',
      titles: {
        name: 'Promotion Name',
        status: 'Status',
        eans: 'EAN(s) Association',
        image: 'Image',
        surveyButton: 'Survey Configuration',
        surveyInfo: 'Survey Info',
        details: 'Detail Configuration',
        typeValue: 'Type & Value',
        pointsColor: 'Points & Color',
        points: 'Sazcoins',
        pointsAndSave: 'Credits & User savings',
        couponCodes: 'Coupon Codes',
        stock: 'Stock Management',
        richTexts: 'Description & Legal Texts',
        retailers: 'Associated Retailers',
        dates: 'Dates',
        order: 'Order Configuration',
        additionalConfig: 'Additional Configuration',
      },
      name: 'Name',
      type: 'Type',
      shortDescription: 'Short description',
      description: 'Description (No character limit)',
      legalText: 'Legal text (No character limit)',
      commentInternal: 'Internal comment',
      questionAdditional: 'Extra questions',
      couponCode: 'Coupon Code',
      status: 'Status',
      startAt: 'Start date',
      endAt: 'End date',
      initialStock: 'Initial Stock',
      safetyStock: 'Safety Stock (%)',
      safetyStockCommon: 'Safety Stock (%) - For all retailers',
      realStock: 'Real Stock',
      availableStock: 'Available Stock',
      value: 'Value',
      color: 'Color',
      points: 'Sazcoins',
      credits: 'Credits',
      saving: 'Saving quantity',
      corePro: 'Corepro',
      pendingStock: 'Pending Stock',
      promotionType: 'Promotion type',
      orderValidity: 'Number of days of order validity',
      orderCancellation: 'Number of days until automatic cancellation',
      retailers: 'Retailers',
      alert: "Some of your changes weren't saved. Are you sure you want to ignore them?",
      orderValidityError: 'The value should not be less than 1 or more than 1000',
      update: 'Promotion has been updated successfully',
      couponCodesStats: {
        total: 'Total',
        distributed: 'Used',
        notDistributed: 'Available',
      },
      createSurveyInfo: 'To enable the button and create a survey, you have to fill and save the promotion name, at least one EAN and upload an image.',
      editSurveyInfo: 'The survey configuration will open in a new tab in Pirate Survey Service.',
    },
    survey: {
      incompleteError: 'Survey is incomplete',
      notCreatedError: 'Please, create a survey',
      create: 'Create Survey',
      edit: 'Edit Survey',
    },
    coupons: {
      upload: 'Upload Coupon Codes',
      uploading: 'Uploading file',
      processing: 'Processing codes',
      refreshing: 'Refreshing counters',
      file: 'Uploaded file: ',
    },
    action: {
      save_and_edit: 'Save and Edit',
      save_and_add: 'Save and Add',
      save_and_show: 'Save and Show',
      save_with_average_note: 'Save with Note',
    },
    filter: {
      name: 'Promotion Name',
      status: 'Status',
      startDate: 'Started since',
      endDate: 'Started before',
      retailer: 'Retailers',
    },
    status: {
      changeStatusTitle: 'Are you sure you want to change the status of this promotion',
      changeStatusPositive: 'Yes',
      changeStatusNegative: 'No',
      manualChangeStatusTitle: 'You configured a start date in the future. So the status will be changed to “602. Ready to publish automatically”, and the promotion will be activated when the activation date arrives. Are you sure you want to schedule this promotion?',
    },
    validation: {
      stockPercentage: 'The percentage must be between 0 and 100',
      mustSafetyStock: 'If you don’t want to configure a safety stock, this value must be 0',
    },
    segmentation: {
      genders: 'Genders',
      man: 'Man',
      woman: 'Woman',
      other: 'Other',
      ageRange: 'User age range',
      ageRangeError: 'Please choose an age range',
      children: 'Children',
      withChildren: 'With children',
      withoutChildren: 'Without children',
      questionChildrenAge: 'Is this promotion for children under 3 years old only?',
      childrenRangeError: 'Please choose a children age range',
      pets: 'Pets',
      withPets: 'With pets',
      withoutPets: 'Without pets',
      petsError: 'Please choose at least a pet type',
      target: 'Estimated number of users targeted:',
      updateSuccess: 'Segmentation updated successfully',
      alcohol: 'Alcohol',
    },
  },
  pickupPoint: {
    list: {
      fields: {
        name: 'Name',
        status: 'Status',
      },
      status: {
        active: 'Active',
        inactive: 'Inactive',
      },
    },
  },
  manufacturer: {
    edit: {
      updated: 'Manufacturer updated successfully',
    },
  },
  user: {
    cards: {
      luhn: 'This loyalty card number is not valid',
      error: 'Error: Please try again.',
      errorQuery: 'There is a technical problem, please contact the IT team.',
      addCardTitle: 'Loyalty card',
      deleteCardTitle: 'Loyalty card',
      confirm: 'Are you sure you want to delete this Loyalty card ?',
      confirmTitle: 'Delete Loyalty Card ',
      errorUser: 'This Loyalty card number is already associated to another user',
      type: 'Type',
      code: 'Card Number',
      success: {
        add: 'Card successfully created to user: ',
        delete: 'Card successfully deleted from user: ',
      },
    },
    list: {
      search: 'Search',
      username: 'Username',
      name: 'Name',
      lastName: 'Last name',
      active: 'Active',
      activatedAt: 'Activated At',
      country: 'Country',
      platform: 'Platform',
    },
    form: {
      name: 'Name',
      link_1: 'See more user details',
      link_2: 'See loyalty card details',
    },
    edit: {
      deleteWarning: ' Do you really want to delete user?',
      title: 'User "%{title}"',
      title1: 'Personal Data',
      title2: 'Personal Profile',
      title21: 'Household composition',
      title22: 'Food profile',
      title3: 'Habits and lifestyle',
      birthDate: 'Child´s birthdate',
      addImage: 'Add image',
      has_pet: 'Do you have a pet?',
      pets: 'Pet type',
      identifier: 'ID Card',
      Dog: 'Dog',
      Cat: 'Cat',
      Bird: 'Bird',
      Mouse: 'Mouse',
      Fish: 'Fish',
      Other: 'Other',
      sports: 'Sports',
      reading: 'Reading',
      cultural: 'Cultural interests ',
      health: 'Nutrition and health conscious',
      selfEducation: 'Seeking education',
      environment: 'Environmentally conscious',
      aesthetics: 'Concerned about health and appearance',
      healthWithoutTime: "Wants a healthy lifestyle, but doesn't have much time",
      socialMedia: 'Enjoys internet. Fan of social networks',
      qualityTime: 'Tends to prioritise quality over economy',
      careerAndFamily: 'Finds satisfaction in his career and his family',
      closeFriends: 'Enjoys time with a small group of friends',
      gardening: 'Gardening lover',
      omnivorous: 'Omnivorous',
      flexitarian: 'Flexitarian',
      vegetarian: 'Vegetarian',
      vegan: 'Vegan',
      other: 'Other',
      has_alcohol: 'Accepts to receive products containing alcohol',
      diets: 'Diet',
      dietOptions: {
        omnivorous: 'Omnivorous',
        vegetarian: 'Vegetarian',
        vegan: 'Vegan',
        rawFood: 'Raw Food',
        flexitarian: 'Flexitarian',
        other: 'Other',
      },
      has_allergy: 'Allergies',
      allergies: 'Allergies',
      allergiesOptions: {
        gluten: 'Gluten',
        milk: 'Milk',
        fish: 'Fish',
        corn: 'Corn-based products',
        molluscs: 'Crustaceans',
        peanuts: 'Peanut',
        eggs: 'Eggs',
        soybeans: 'Soybeans',
        almonds: 'Tree nuts',
        other: 'Other',
      },
      communication: 'Communications',
      lifestyles: 'Lifestyles',
      diet: 'Diet',
      interest: 'Hobbies',
      numberOfChildren: 'Number of children',
      yes: 'Yes',
      no: 'No',
      not_answered: 'Unanswered',
      shuffleConnection: 'Accepts that carrefour shares his purchase information',
      prefix: 'Telephone prefix',
      username: 'Email',
      home: 'The number of people in the household',
      telephone: 'Phone number',
      verifiedYes: 'Verified phone number',
      verifiedNo: 'Not verified phone number',
      telephoneVerifiedAt: 'Is telephone verified?',
      name: 'Name',
      lastname: 'Last Name',
      activated: 'Activated',
      conditions: 'Conditions',
      isAdmin: 'Is Admin',
      monavisId: 'MALRG user id',
      externalId: 'External user id',
      createdAt: 'Created At',
      registeredFromApp: 'Source',
      updatedAt: 'Updated At',
      activatedAt: 'Activated At',
      lastAccessedAt: 'Last Accessed At',
      communicationsPolicy: 'Comunications',
      country: 'Country',
      platform: 'Platform',
      gender: {
        title: 'Gender',
        man: 'Man',
        woman: 'Woman',
        other: 'Other',
        none: '-',
      },
      birth: 'Birthdate',
      postalCode: 'Zip code',
      city: 'City',
      province: 'Province',
      address_1: 'Address',
      address_3: 'Address Comp',
      address_2: 'District',
      preferences: {
        alcohol: 'Alcohol',
        bio: 'Bio',
        light: 'Light',
        gluten: 'Gluten',
      },
      educationOptions: 'Choose education level',
      firstName: 'Name',
      numberOfHomeComponentsOptions: 'Number of home components',
    },
    filter: {
      active: 'Active',
      external: 'External user ID',
      username: 'Username',
      activatedAt: 'Activated At',
      filterMixed: 'Platform & Country',
    },
    additionalPoints: {
      title: 'Additional points',
      noResults: 'User has not scheduled points',
      status: {
        expired: 'Expired',
        programmed: 'Scheduled',
        available: 'Available',
      },
    },
    tabs: {
      user: 'Detail',
      orders: 'Orders',
      promoDetail: 'Detail',
      promoSegmentation: 'Segmentation',
      promoRouter: 'Router',
      profile: 'Profile',
      policies: 'Policies',
      points: 'Points',
      additionalPoints: 'Additional Points',
      cards: 'Loyalty Card',
    },
  },
  orders: {
    list: {
      promotionId: 'Promotion Id',
      promotionName: 'Promotion Name',
      promotionKey: 'Promotion Key',
      requestedAt: 'Requested At',
      expiredAt: 'Expired At',
      redeemedAt: 'Redeemed At',
      evaluatedAt: 'Evaluated At',
      cancelledAt: 'Cancelled At',
      status: 'Status',
      num: 'Num',
      statusHistory: 'Status History',
      date: 'Date',
      retailer: 'Retailer',
      cancellationReason: 'Reason',
    },
    filter: {
      promotionName: 'Promotion Name',
    },
    action: {
      save_and_add: 'Save and Add',
      save_and_show: 'Save and Show',
    },
    tabs: {
      user: 'Detail',
      orders: 'Orders',
    },
    statusChange: {
      statusUpdateTitle: 'Update status reason',
      statusUpdateDescription: 'Reason',
      selectRetailerTitle: 'Select a retailer',
      submit: 'Submit',
      cancel: 'Cancel',
      concept: {
        requested: 'Solicitud:',
        cancelled: 'Cancelación:',
        redeemed: 'Redención:',
      },
    },
    export: {
      title: 'Home delivery orders export',
      labelFrom: 'Date FROM',
      labelUntil: 'Date UNTIL',
      request: 'Search & Export',
      loading: 'Loading...',
      noResults: 'The search has not found any results.',
      results: 'Found %{total} results.',
      error: 'An error ocurred, please try again.',
    },
  },
  comment: {
    edit: {
      filterMixed: 'Platform & Country',
      pageTitle: 'Detail',
      ean: 'EAN',
      author: 'Author',
      username: 'Username',
      rating: 'Rating',
      createdAt: 'Created At',
      purchasedAt: 'Purchased At',
      status: 'Status',
      certified: 'Certified',
      title: 'Comment title',
      text: 'Comment text',
      addEan: 'Please add an EAN',
      platform: 'Comment Platform',
      country: 'Comment Country',
      language: 'Comment Language',
      authorName: 'Author Name',
      authorMail: 'Author Mail',
      code: 'Source',
      referenceId: 'Promotion Id',
      authorAnonymous: 'Is author anonymized?',
      authorCountry: 'Author Country',
      authorLanguage: 'Author Language',
      authorPlatform: 'Author Platform',
    },
    list: {
      ean: 'EAN',
      author: 'Author',
      username: 'Username',
      rating: 'Rating',
      createdAt: 'Created At',
      status: 'Status',
      certified: 'Certified',
      text: 'Comment',
      anonymous: 'Anonymous user',
      country: 'Country',
      prev: 'Previous page',
      next: 'Next page',
      zero: 'No results found',
      filter: {
        startDate: 'From date',
        endDate: 'To date',
      },
    },
  },
  blacklist: {
    save: 'Save list',
    add: 'Add new word',
    filter: 'Filter by word',
    questionRemove: 'Remove %{selectedWord}?',
    addQuestion: 'What word do you want to add?',
    confirm: 'Confirm',
    cancel: 'Cancel',
    remove: 'Remove',
    existingTerm: 'This term already exist',
  },
  products: {
    shuffle: {
      title: 'Shuffle',
      file: 'Add shuffle: ',
      fileClaim: 'Download CSV template',
      fileDelete: 'Delete shuffle: ',
      btn: 'Add Shuffle type',
      label: 'Shuffle type',
      delete: 'Delete Shuffle type',
      aria: 'Shuffle type',
      success: 'File uploaded',
      error: 'Error: File was not uploaded.',
    },
    delete: {
      confirm: 'Are you sure?.This action cant be undone',
      confirmTitle: 'Delete product confirmation',
      button: 'Delete product',
      notifyOK: 'Product has been deleted',
      notifyKO: 'Delete product failed',
    },
    list: {
      ean: 'EAN',
    },
    filter: {
      filters: 'Filters',
      accolades: 'With accolades',
      errorEanMax: 'The maximum number of EANs introduced cannot exceed 5.000',
      errorEanLenght: 'Please introduce between 6 and 13 digits',
    },
    add: {
      accolades: 'Add accolades',
      chooseAccolades: 'Choose accolades',
      correctAccolades: '%{total} accolades have been added successfully ',
      errorAccolades: '%{total} accolades could not be added ',
      correctAccoladeMessage: 'Accolade has been successfully added to this product',
      notifyMessage: 'Add accolades task has finished',
      removeResults: 'Remove results',
      select: 'Select an accolade',
      result: 'Result',
      cancel: 'Cancel',
      addButton: 'Add accolade to selected products',
      finishedAccolade: 'Update product accolades has done, please check it',
    },
    tabs: {
      accolades: 'Accolades',
    },
    edit: {
      ean: 'EAN',
      accolade: 'Accolade',
      season: 'Season',
      accoladesList: 'Accolades List',
      accolades: {
        selectAccolade: 'Select an accolade',
        startDate: 'Start date',
        endDate: 'End date',
      },
      errors: {
        endDate: 'End date must be greater than start date',
      },
      updated: 'Product updated successfully',
    },
    uploads: {
      jobs: {
        title: 'Processing ',
        titleProgress: 'Current in progress',
        titleComplete: 'Total EANs processed',
        titleCompleteOK: 'EANs processed successfully',
        titleError: 'EANs processed with errors',
        title_2: ' EANs',
      },
      button: 'Start process',
      buttonSaving: 'Process running',
      processing: {
        title: 'Upload Status',
        span: 'Status',
        status: {
          upload: 'Uploading csv file',
          process: 'Processing csv file',
          end: 'End',
        },
      },
      form: {
        label: 'Upload CSV',
        placeholder: 'Upload your CSV file or drag here',
        preview: 'Your uploaded file',
      },
      buttonLabel: 'Upload products info',
      title: 'Upload products',
      file: {
        title: 'Download Template with labels and categories.',
        encodeError: 'The file doesn\'t have a valid format (%{actual}), please upload a CSV UTF-8 comma delimited file.',
      },
    },
    jobsList: {
      deleting: {
        success: 'Job deleted successfully.',
        error: 'Error deleting job, please try again.',
      },
    },
    jobDetail: {
      title: 'CSV Job ID %{id}',
      noJobId: 'No Job ID provided.',
      labels: {
        status: 'Status:',
        progress: 'Progress:',
        startedAt: 'Started at:',
        finishedAt: 'Finished at:',
        eanProcessed: 'EAN Processed:',
        eanInProgress: 'EAN In Queue:',
        parts: 'Parts',
      },
    },
    jobsStatusLegend: {
      title: 'Jobs Status Legend',
      created: {
        title: 'CREATED',
        desc: 'The process is scheduled to start, but it has not started yet.',
      },
      started: {
        title: 'STARTED',
        desc: 'The process has started.',
      },
      finished: {
        title: 'FINISHED',
        desc: 'The process is finished.',
      },
    },
    syndication: {
      single: {
        addSuccess: 'EAN %{ean} syndicated successfully.',
        addError: 'An error occurred syndicating EAN %{ean}. Please try again later.',
        delSuccess: 'Deleted syndication of EAN %{ean} successfully.',
        delError: 'An error occurred deleting EAN %{ean} syndication. Please try again later.',
        nothingToDo: 'Nothing to do, this EAN is the same status as sent.',
      },
      steps: {
        1: 'Insert EANs',
        2: 'Select action',
        3: 'Complete',
        continue: 'Continue',
        prev: 'Go back',
        start: 'Go to start',
      },
      stepOne: {
        title: 'Please insert EANs comma-separated (max 6.000)',
        errorMaxEans: 'The maximum number of EANs introduced cannot exceed 6.000',
      },
      stepTwo: {
        title: 'Please select action',
        buttonSyndication: 'Syndication',
        buttonUnSyndication: 'Delete Syndication',
        diff: {
          insertedAndFound: 'Inserted %{inserted} EANs. Found %{found} in database.',
          list: 'Not found list:',
        },
      },
      stepThree: {
        title: 'Syndication',
        subtitle: 'Eans (found in database) processed',
        nothingToDo: 'Nothing to do, EANs are in the same status as sent.',
        success: 'EANs processed successfully.',
      },
    },
  },
  seasons: {
    list: {
      id: 'ID',
      name: 'Name',
      startDate: 'Start date',
      endDate: 'End date',
      accolade: 'Accolade',
    },
    show: {
      id: 'ID',
      name: 'Name',
      startDate: 'Start date',
      endDate: 'End date',
      accolade: 'Accolade',
    },
    error: {
      list: 'Sorry there was an error fetching the seasons',
    },
  },
  points: {
    title: 'Points',
    list: {
      concept: 'Transaction Concept',
      date: 'Transaction Date',
      number: 'Transaction points',
      userPoints: 'User %{user} has %{points} points',
    },
    actions: {
      add: 'Add',
      pointsAdded: 'Points have been added succesfully',
      pointsSubtracted: 'Points have been subtracted succesfully',
      subtract: 'Subtract',
      concept: 'Concept',
      confirm: 'Confirm',
      amount: 'Number of points',
      minPoints: 'Min 0',
      cancel: 'Cancel',
      addPoints: 'Add points',
      subtractPoints: 'Subtract points',
      startDate: 'Start date',
      endDate: 'End date',
    },
  },
  retailers: {
    list: {
      name: 'Name',
      status: 'Status',
    },
    filter: {
      name: 'Retailer Name',
      status: 'Status',
    },
    edit: {
      name: 'Name',
      description: 'Description',
      logo: 'Logo',
      status: 'Status',
      format: 'Format',
      city: 'City',
      postalCode: 'Postal Code',
      latitude: 'Latitude',
      longitude: 'Longitude',
      line1: 'Address',
      line2: 'Complementary Address',
      creationDate: 'Creation Date',
    },
    tabs: {
      details: 'Details',
      promotions: 'Promotions',
      detail: 'Details',
    },
  },
  unsub: {
    title: 'Unsubscriptions',
  },
  cards: {
    history: {
      title: 'Card history search',
      search: 'History search',
      searchLabel: 'Card Number',
      list: {
        title: 'History',
        code: 'Card Number',
        type: 'Type',
        user: 'Owner',
        createdAt: 'Created At',
        deletedAt: 'Deleted At',
        deletedUser: 'Deleted user',
      },
    },
  },
};

export default messages;
